import React from "react";
import "./styles.scss";
type Props = {
  isActive?: boolean;
};

class WeekViewIcon extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <svg
          viewBox="0 0 385 391"
          version="1.1"
          className={this.props.isActive ? "viewIcon highlight" : "viewIcon"}
        >
          <g
            className={this.props.isActive ? "rosterIcon active" : "rosterIcon"}
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            // fill="grey"
            fillRule="evenodd"
          >
            <path
              d="M62,0 C66.1008667,-7.53316995e-16 69.6251924,2.46846569 71.1681707,6.00059079 L313.831829,6.00059079 C315.374808,2.46846569 318.899133,7.53316995e-16 323,0 L360,0 C373.669048,-2.5109633e-15 384.775881,10.9701429 384.996651,24.5865793 L385,25 L384.906002,25.0000253 C384.968195,25.6583099 385,26.3254407 385,27 L385,370 C385,381.59798 375.59798,391 364,391 L21,391 C9.40202025,391 1.42034288e-15,381.59798 0,370 L0,27 C-8.26096882e-17,26.3254407 0.0318050991,25.6583099 0.0939975094,25.0000253 L0,25 C-1.69088438e-15,11.1928813 11.1928813,2.53632657e-15 25,0 L62,0 Z M346,36 L39,36 C34.1181973,36 30.1442086,39.8868137 30.0038371,44.7346377 L30,45 L30,352 C30,356.881803 33.8868137,360.855791 38.7346377,360.996163 L39,361 L346,361 C350.881803,361 354.855791,357.113186 354.996163,352.265362 L355,352 L355,45 C355,40.0294373 350.970563,36 346,36 Z M110,76 L110,116 L70,116 L70,76 L110,76 Z M178,76 L178,116 L138,116 L138,76 L178,76 Z M247,76 L247,116 L207,116 L207,76 L247,76 Z M315,76 L315,116 L275,116 L275,76 L315,76 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </svg>
      </>
    );
  }
}

export default WeekViewIcon;

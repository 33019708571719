import React, { useState } from "react";
import "./styles.scss";
import { getEncodedRegisterUrl } from "../helpers/routeHelpers";
import getStartedImg from "../images/trustpilot-review-img-2.png";

const GetStartedNew = () => {
  const [email, setEmail] = useState("");

  return (
    <div>
      <div className="getStarted pt-180 pb-180 justify-content-center">
        <div className="container getStartedWrapper">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 getStartedTextWrapper">
            <div className="actionWrapper">
              <h2 className="small customH2">Jetzt starten</h2>
              <div className="mb-16 color-heading secondaryText">
                Digitalisieren Sie Ihre Dienstplanung kostenlos mit Aplano.
              </div>
              <div className="mt-10 actionFieldWrapper">
                <input
                  style={{ marginRight: 10 }}
                  type="email"
                  name="email"
                  placeholder="Ihre Email Adresse"
                  className="input radius4 focus-action-1 color-heading placeholder-heading inputCustomizedGetStarted"
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <a href={getEncodedRegisterUrl(email)}>
                  <button
                    className="btn action-1 text-center radius4 buttonCustomizedGetStartedNew"
                    style={{ textAlign: "center" }}
                  >
                    Jetzt registrieren
                  </button>
                </a>
              </div>
              <div className="alrdyMemberText">
                Sie sind bereits registriert?{" "}
                <a href="https://web.aplano.de" className="loginLinkGetStarted">
                  Zum Login
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-6 col-lg-6 col-sm-14 getStartedImgWrapper">
            <img
              alt="Aplano Zeiterfassung Erstellen"
              src={getStartedImg}
              className="getStartedImg"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default GetStartedNew;

import React, { useState } from "react";
import "./styles.scss";
import { Navigation } from "..//Navigation/Navigation";
import landingImg from "../images/iphone-macbook-landing-new.png";
import smallLandingImg from "../images/iphone-macbook-landing-new.png";

type Props = {
  title1: string;
  title2?: string;
  titleSmall?: boolean;
  titleExtraSmall?: boolean;
  subTitle: string;
  landingImg: string;
  minWidth: number;
  showLiveDemoButton?: boolean;
  smallLandingImg?: string;
  imgAlt: string;

  marginLeft?: number;
  dynamicTitle?: boolean;
  lgKey?: "en" | "de";
  fontSize?: number;
};

export const CustomHeader = (props: Props) => {
  const [email, setEmail] = useState("");

  const title1Class = props.titleSmall
    ? "customHeaderTitleFontSizeSmall"
    : props.titleExtraSmall
    ? "customHeaderTitleFontSizeExtraSmall"
    : "customHeaderTitleFontSize";

  const title2Class = props.titleSmall
    ? "subHeaderFontSizeSmall"
    : props.titleExtraSmall
    ? "subHeaderFontSizeExtraSmall"
    : "subHeaderFontSize";

  return (
    <header className="customHeaderMain">
      <div>
        <Navigation backgroundCol="#f9f9f9" />
      </div>

      <section className="headerMainContainer container">
        <div className="col-12 col-xl-12 col-lg-12 col-sm-10 customFormAndImgWrapper">
          <div className="headerFormWrapperMain">
            <div className="titleWrapper">
              <h1 className={title1Class + " customHeaderMainTitle"}>
                {props.title1}
                <span
                  style={{
                    color: "#19a0ff",
                    fontWeight: 600,
                    paddingLeft: 10
                  }}
                >
                  kostenlos
                </span>
              </h1>
            </div>

            <img
              className="onlySmallScreen"
              style={{ marginBottom: 12, maxWidth: 500, width: "100%" }}
              alt={props.imgAlt}
              src={smallLandingImg}
            />
            <p className="desktopSubHeaderText">{props.subTitle}</p>

            {/* {renderInputField()} */}
            <div className="customHeaderButtonWrapper">
              <a
                href={"https://www.aplano.de/registrieren"}
                className="customHeaderButton"
              >
                {"Kostenlos testen"}
              </a>
            </div>
            <p style={{ opacity: 0.6, marginTop: 15, fontSize: 14 }}>
              {"Bis zu 50 Mitarbeiter kostenlos"}
            </p>
          </div>
          <img
            src={landingImg}
            alt="Aplano Dienstplaner Header"
            style={{
              minWidth: props.minWidth,
              marginLeft: props.marginLeft ? props.marginLeft : 90
            }}
            className="customHeaderImage"
          />
        </div>
      </section>
    </header>
  );
};

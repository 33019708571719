import React, { useState, useEffect } from "react";
import WeekViewIcon from "../RosterViewIcons/WeekViewIcon";
import MonthViewIcon from "../RosterViewIcons/MonthViewIcon";
import DayViewIcon from "../RosterViewIcons/DayViewIcon";
import StackViewIcon from "../RosterViewIcons/StackViewIcon";
import "./styles.scss";
import firstImg from "../images/weekview-img.jpg";
import secondImg from "../images/monthview-img.jpg";
import thirdImg from "../images/dayview-img.jpg";
import fourthImg from "../images/stackview-img.jpg";

let timeoutRef: any;

const InteractiveImgRotator = (props: any) => {
  const [imageId, setImageId] = useState("weekViewIcon");
  const [videoCount, setVideoCount] = useState(0);
  const [activeClass, setAnimationClass] = useState("visible");

  const handleAnimationChange = () => {
    setAnimationClass("invisible");
    setTimeout(() => setAnimationClass("visible"), 100);
  };

  // const highlightIcon = id => {
  //   handleAnimationChange();
  //   setTimeout(() => setImageId(id), 100);
  // };

  const handleSetImageId = (num: any, id: any) => {
    handleAnimationChange();
    setTimeout(() => setImageId(id), 100);

    setVideoCount(num);
  };

  // const handleVideoCount = num => {
  //   setVideoCount(num);
  // };

  useEffect(() => {
    timeoutRef = setTimeout(() => setVideoIcon(), 10000);
    return () => {
      clearTimeout(timeoutRef);
    };
  }, [videoCount]);

  const setVideoIcon = () => {
    let currentCount = videoCount === 3 ? 0 : videoCount + 1;
    setVideoCount(currentCount);

    const videoimageIds = imageList.map(vI => vI.id);
    handleAnimationChange();
    setTimeout(() => setImageId(videoimageIds[currentCount]), 100);
  };

  const imageList = [
    {
      id: "weekViewIcon",
      name: "Wochenansicht",
      path: firstImg,
      component: <WeekViewIcon isActive={imageId === "weekViewIcon"} />,
      backgroundCol: "rgba(234, 253, 255, 0.7)"
    },
    {
      id: "monthViewIcon",
      name: "Monatsansicht",
      path: secondImg,
      component: <MonthViewIcon isActive={imageId === "monthViewIcon"} />,
      backgroundCol: "rgba(232, 243, 255, 0.7)"
    },
    {
      id: "dayViewIcon",
      name: "Tagesansicht",
      path: thirdImg,
      component: <DayViewIcon isActive={imageId === "dayViewIcon"} />,
      backgroundCol: "rgba(237, 240, 255, 0.7)"
    },
    {
      id: "stackViewIcon",
      name: "Boardansicht",
      path: fourthImg,
      component: <StackViewIcon isActive={imageId === "stackViewIcon"} />,
      backgroundCol: "rgba(244, 232,254, 0.7)"
    }
  ];

  const currentVideoPath = imageList.find(vL => vL.id === imageId)!.path;
  // const currentBackground = imageList.find(vL => vL.id === imageId)!
  //   .backgroundCol;

  return (
    <div>
      <section className="feature_11 shiftplanViewRotatorMain">
        <div
          className="viewRotatorContainer"
          style={{
            // backgroundColor: currentBackground
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div className="rotationHeader container">
            Ansichten des Schichtplans
          </div>
          <div className="rotationDescription container">
            Arbeiten Sie mit verschiedenen Ansichten um die Schichtplanung
            speziell auf Ihre Bedürfnisse anzupassen und effizienter
            Planungsprozesse in Ihrem Betrieb zu gestalten.
          </div>

          <div className="container rotationWrapper">
            <div className="rotationButtonContainer">
              {imageList.map((item, i) => {
                const highlightItem = item.id === imageId ? "activeItem" : "";
                // const highlightLine = item.id === imageId ? "activeLine" : "";
                return (
                  <div className="viewButtonWrapper">
                    <div
                      className={"shiftplanViewItem" + " " + highlightItem}
                      onClick={() => handleSetImageId(i, item.id)}
                      key={item.id}
                    >
                      <div className="viewIconWrapper">{item.component}</div>

                      <div className="shiftplanViewItemName">{item.name}</div>
                    </div>
                    {/* <div
                      className={"connectingLine" + " " + highlightLine}
                    ></div> */}
                  </div>
                );
              })}
            </div>

            <div
              className={"backgroundImageWrapper" + " " + activeClass}
              style={{ margin: 0 }}
            >
              <img src={currentVideoPath} className="backgroundImage" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InteractiveImgRotator;

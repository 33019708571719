import React, { useState } from "react";

import "./styles.scss";

import { Shiftplan2 } from "./Shiftplan2/Shiftplan2";
import { Shiftplan4 } from "./Shiftplan4/Shiftplan4";
import { Shiftplan3 } from "./Shiftplan3/Shiftplan3";
import { Shiftplan1 } from "./Shiftplan1/Shiftplan1";
import { Shiftplan5 } from "./Shiftplan5/Shiftplan5";
import GetStarted from "../GetStarted/GetStarted";
import { getEncodedRegisterUrl } from "../helpers/routeHelpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import InteractiveImgRotator from "../InteractiveImgRotator/InteractiveImgRotator";
import FunctionPageHeader from "../FunctionPageHeader/FunctionPageHeader";
import References from "../References/References";
import { Navigation } from "../Navigation/Navigation";
import { ClockingFeature } from "../ClockingFeature/ClockingFeature";
import { IndustrySection2 } from "../IndustrySection2/IndustrySection2";
import Footer from "../Footer/Footer";
import { FeatureWrapper } from "../FeatureWrapper/FeatureWrapper";
import HeaderSection from "../HeaderSection/HeaderSection";
import GifSection from "../GifSection/GifSection";
import GetStartedNew from "../GetStartedNew/GetStartedNew";
import { CustomHeader } from "../CustomHeader/CustomHeader";

const ShiftplanPage = () => {
  const textFeatureShiftplan =
    "Erstellen Sie Schichtpläne mit wenigen Klicks und beziehen sie das Team in die Schichtplanung mit ein. Mithilfe von Wochenplan Vorlagen und der Drag & Drop Funktion wird der Aufwand der Schichtplanung wesentlich reduziert. Per App hat jeder Mitarbeiter Zugriff auf den Dienstplan und wird stets in Echtzeit über Änderungen informiert.";
  const textFeatureClocking =
    "Um Arbeitszeiten über den Schichtplaner zu erfassen, können sich die Mitarbeiter mit ihrem Smartphone ein- und ausstempeln sobald sie sich in der Nähe des Arbeitsplatzes befinden. Alternativ kann auch ein Tablet oder PC als Stempeluhr Station verwendet werden, an dem sich das gesamte Team ein- und ausstempelt.";
  const textFeatureAbsence =
    "Ein online Schichtplaner funktioniert besonders gut mit einem integrierten Urlaubsplaner, sodass Ausfälle durch Urlaub und Krankheit direkt im Dienstplan angezeigt werden können. Mit der Aplano App haben die Mitarbeiter Zugriff auf den Schichtplaner und können ihre Urlaubsanträge digital einreichen und einsehen.";

  const [email, setEmail] = useState("");

  const metaDescription =
    "Erstellen Sie Schichtpläne online und integrieren Sie Mitarbeiter in die Schichtplanung, mobil, per Tablet oder Laptop.";

  return (
    <div className="mainBetaPage">
      <Navigation backgroundCol={"white"} fontColor={"white"} />

      {/* <FunctionPageHeader
        bright={false}
        image={"../images/aplano-beta-landing-img.jpg"}
        imageAlt="Schichtplan erstellen Aplano"
        title="Schichtplaner"
        text="Erstellen Sie Schichtpläne im Handumdrehen und steigern Sie
        die Effizienz in Ihrem Betrieb mit dem digitalen online
        Dienstplan von Aplano."
      /> */}
      {/* <HeaderSection /> */}

      <CustomHeader
        title1="Schichtplaner online"
        // title2="kostenlos"
        subTitle="Erstellen Sie Schichtpläne im Handumdrehen und steigern Sie die Effizienz in Ihrem Betrieb mit dem digitalen online Dienstplan von Aplano."
        landingImg={"../images/iphone-macbook-landing-new.png"}
        smallLandingImg={"../images/iphone-macbook-landing-new.png"}
        minWidth={1000}
        showLiveDemoButton
        imgAlt={"Schichtplaner kostenlos - Aplano"}
      />
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="container"> */}
      <References />
      <InteractiveImgRotator />
      <IndustrySection2
        text={
          "Nachdem der Schichtplan online erstellt wurde, können sich die Mitarbeiter zu ihren zugewiesenen Schichten mit ihrem Handy oder an einer Stempeluhr Station als Tablet Einstempeln. Gestempelte Arbeitszeiten müssen von einem Manager bestätigt werden um vom Programm übernommen zu werden."
        }
      />
      <GifSection />
      <Shiftplan2 />
      <Shiftplan1 />
      {/* </div> */}

      {/* <Shiftplan3 />
      <Shiftplan4 />

      <Shiftplan5 /> */}
      {/* </div> */}
      <FeatureWrapper
        titleA={"Schichtplaner"}
        titleB={"Zeiterfassung"}
        titleC={"Abwesenheiten"}
        textA={textFeatureShiftplan}
        textB={textFeatureClocking}
        textC={textFeatureAbsence}
      />
      <GetStartedNew />
      <Footer />
    </div>
  );
};

export default ShiftplanPage;

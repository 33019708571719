import React, { useState } from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

type Props = {
  link: string;
  linkText: string;
  fontWeight?: number;
  fontSize?: number;
  paddingLeft?: number;
};

export const CustomLinkButton = (props: Props) => {
  return (
    <a href={props.link} className="startFreeLinkButton">
      <div
        className="startLinkButton"
        style={{
          fontSize: props.fontSize ? props.fontSize : 18,
          display: "flex",
          alignItems: "center",
          fontWeight: props.fontWeight ? props.fontWeight : 500,
          paddingLeft: props.paddingLeft
        }}
      >
        <div className="linkTextWrapper">{props.linkText} </div>
        <FontAwesomeIcon
          icon={faLongArrowAltRight}
          style={{
            marginLeft: 10,
            fontSize: 22,
            maxWidth: 25,
            color: "#19a0ff"
          }}
        />
      </div>
    </a>
  );
};

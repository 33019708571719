import React from "react";
import "./styles.scss";
type Props = {
  isActive?: boolean;
};

class StackViewIcon extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {/* <svg width="32px" height="36px" viewBox="0 0 385 391" version="1.1">
          <g
            className={this.props.isActive ? "rosterIcon active" : "rosterIcon"}
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fillRule="evenodd"
          >
            <path
              d="M523,0 C535.702549,-2.33342044e-15 546,10.2974508 546,23 L546,520 C546,532.702549 535.702549,543 523,543 L23,543 C10.2974508,543 1.55561363e-15,532.702549 0,520 L0,23 C-1.55561363e-15,10.2974508 10.2974508,2.33342044e-15 23,0 L523,0 Z M494,51 L52,51 L52,492 L494,492 L494,51 Z M187,114 L187,423 L119,423 L119,114 L187,114 Z M427,112 L427,313 L359,313 L359,112 L427,112 Z M307,114 L307,261 L239,261 L239,114 L307,114 Z"
              id="Combined-Shape"

            ></path>
          </g>
        </svg> */}

        <svg
          viewBox="0 0 32 32"
          version="1.1"
          className={this.props.isActive ? "viewIcon highlight" : "viewIcon"}
        >
          <g
            className={this.props.isActive ? "rosterIcon active" : "rosterIcon"}
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fillRule="evenodd"
          >
            <path
              d="M2.5,0 C3.06278272,-1.03381509e-16 3.58213239,0.185959038 3.99995493,0.499783055 L28.0000451,0.499783055 C28.4178676,0.185959038 28.9372173,1.03381509e-16 29.5,0 L31,0 C31.5522847,-1.01453063e-16 32,0.44771525 32,1 L32,29.5 C32,30.6045695 31.1045695,31.5 30,31.5 L2,31.5 C0.8954305,31.5 1.3527075e-16,30.6045695 0,29.5 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L2.5,0 Z M29.5,2.728 L2.5,2.728 L2.5,29.25 L29.5,29.25 L29.5,2.728 Z M10,5.82857143 L10,22.8285714 L6.5,22.8285714 L6.5,5.82857143 L10,5.82857143 Z M25.6,5.81428571 L25.6,16.4428571 L22.1,16.4428571 L22.1,5.81428571 L25.6,5.81428571 Z M17.9,6 L17.9,13.0857143 L14.4,13.0857143 L14.4,6 L17.9,6 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </svg>
      </>
    );
  }
}

export default StackViewIcon;

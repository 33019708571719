import React from "react";
import { Helmet } from "react-helmet";
import "./App.scss";
import ShiftplanPage from "./ShiftplanPage/ShiftplanPage";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Schichtplaner kostenlos | Aplano</title>
        <meta
          name="description"
          content="Schichtplan online erstellen und kostenlos loslegen. Schichtpläne online anlegen und Mitarbeiter per App einladen."
        />
      </Helmet>
      <ShiftplanPage />
    </div>
  );
}

export default App;

import React, { useState } from "react";
import "./styles.scss";
import VisibilitySensor from "react-visibility-sensor";
import DummyImg from "../images/aplano-dragdrop-dummy-img.png";
import DragDropVid from "./drag-drop-video.mp4";
import { CustomLinkButton } from "../CustomLinkButton/CustomLinkButton";

const GifSection = (props: any) => {
  const title = "Dienstplan per Drag & Drop";
  const text =
    "Schichten einzeln zu erstellen und anzulegen kann viel Zeit in Anspruch nehmen. Mit der Drag & Drop Funktion können Dienstpläne wesentlich schneller bearbeitet werden. Ziehen Sie Schichten ganz einfach auf das gewünschte Feld um diese zu verschieben oder zu duplizieren.";

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="vidItemLeftBlockMain"
      style={{ backgroundColor: props.backgroundCol }}
    >
      <div
        className="container vidItemWrapper"
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
          justifyContent: "space-between"
        }}
      >
        <div
          className="vidItemImageWrapper col-xl-6 col-lg-6 col-sm-14"
          style={{
            padding: 0
          }}
        >
          <VisibilitySensor delayedCall onChange={v => v && setIsVisible(v)}>
            {isVisible ? (
              <video id="dragDropVid" autoPlay={true} loop muted>
                <source src={DragDropVid} type="video/mp4" />
              </video>
            ) : (
              <img alt={title} src={DummyImg} className="dummyImg" />
            )}
          </VisibilitySensor>
        </div>
        <div className={"vidItemImageDescription col-xl-4 col-lg-4 col-sm-12"}>
          <div className="vidItemBlockTitle">Planen per Drag & Drop</div>
          <div className="vidItemBlockText">{text}</div>
          <CustomLinkButton
            link="https://www.aplano.de/registrieren"
            linkText="Jetzt loslegen"
          />
        </div>
      </div>
    </div>
  );
};

export default GifSection;

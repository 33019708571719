import React, { useState } from "react";
import "./styles.scss";
import { useWindowWidth } from "../helpers/useWindowWidth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { useScroll } from "../helpers/useScroll";
import BlueAplanoLogo from "../BlueAplanoLogo";
import aplanoLogoWhite from "../static/i/aplano-logo-white.svg";

export const MobileNavigation = props => {
  const width = useWindowWidth();
  const scroll = useScroll();
  const [isOpen, setIsOpen] = useState(false);
  if (!width || width >= 1200) {
    return <div></div>;
  }

  return (
    <div>
      {isOpen && (
        <div className="underlay" onClick={() => setIsOpen(false)}></div>
      )}
      <div style={{ zIndex: 20, position: "fixed", left: "20px", top: "20px" }}>
        <div
          className={
            scroll.y > 1
              ? "mobileTopbar showMobileHeader"
              : "mobileTopbar hideMobileHeader"
          }
        >
          <a href="/#">
            <BlueAplanoLogo
              style={{ height: 38, width: "auto" }}
              fillBg={
                scroll.y > 1 || width > 491 || !props.displayWhite
                  ? "black"
                  : "white"
              }
            />
            {/* {scroll.y > 1 || width > 491 || !props.displayWhite ? (
              <img
                src="../../i/aplano-whole-logo.svg"
                alt="logo Aplano"
                style={{ width: 100 }}
              />
            ) : (
              <img
                src="../../i/aplano-whole-logo.svg"
                alt="logo Aplano"
                style={{ width: 100 }}
              />
            )} */}
          </a>
          <a className={"menuButton"} onClick={() => setIsOpen(true)}>
            <FontAwesomeIcon
              icon={faBars}
              className="lh-40"
              style={{
                color:
                  scroll.y > 1 || width > 491 || !props.displayWhite
                    ? "black"
                    : "white"
              }}
            />
          </a>
        </div>
        <div
          className={`navigation_mobile bg-dark type1 ${
            isOpen ? "opened" : ""
          }`}
        >
          <a
            className="close_menu color-white"
            onClick={() => setIsOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </a>
          <div className="px-40 pt-60 pb-60 inner">
            <div className="logo color-white mb-30">
              <img width={150} src={aplanoLogoWhite} style={{ width: 150 }} />
            </div>

            <>
              <div>
                <a
                  href="https://www.aplano.de"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Home
                </a>
              </div>
              <div>
                <a
                  href="https://www.aplano.de/funktionen"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Funktionen
                </a>
              </div>
              <div>
                <a
                  href="/https://www.aplano.depreise"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Preise
                </a>
              </div>
              <div>
                <a
                  href="https://www.aplano.de/kontakt"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Kontakt
                </a>
              </div>
              <div>
                <a
                  href="https://www.aplano.de/blog"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Unser Blog
                </a>
              </div>
              <div>
                <a
                  href="https://web.aplano.de"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Login
                </a>
              </div>
              <div>
                <a
                  href="https://www.aplano.de/registrieren"
                  className="f-heading f-22 link color-white mb-20"
                >
                  Registrieren
                </a>
              </div>
              <div>
                <a
                  href="https://www.aplano.de/faq"
                  className="f-heading f-22 link color-white mb-20"
                >
                  FAQ
                </a>
              </div>
            </>
            <div>
              <a
                href="https://www.aplano.de/datenschutz"
                className="link color-white op-3 mb-15"
              >
                Datenschutzerklärung
              </a>
            </div>
            <div>
              <a href="/agb" className="link color-white op-3 mb-15">
                AGB
              </a>
            </div>
            <div>
              <a
                href="https://www.aplano.de/impressum"
                className="link color-white op-3 mb-15"
              >
                Impressum
              </a>
            </div>
            <div className="socials mt-40">
              <a
                href="https://twitter.com/aplano_de"
                rel="noopener noreferrer"
                target="_blank"
                className="link color-white f-18 mr-20"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="https://www.facebook.com/AplanoDE"
                rel="noopener noreferrer"
                target="_blank"
                className="link color-white f-18 mr-20"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.linkedin.com/company/aplano/"
                rel="noopener noreferrer"
                target="_blank"
                className="link color-white f-18 mr-20"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
            <div className="mt-50 f-14 light color-white op-3 copy">
              © {new Date().getFullYear()} Aplano GmbH
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "./styles.scss";
import shiftplan2Img from "../../images/multi-smartphone-mock.png";
import { CustomLinkButton } from "../../CustomLinkButton/CustomLinkButton";

export const Shiftplan2 = () => {
  return (
    <section className="feature_11 shiftPlan2Main">
      <div
        className="container row shiftPlan2Wrapper pr-0 pl-0"
        style={{ alignItems: "center" }}
      >
        <div className="col-xl-5 col-lg-5 col-sm-14 no-gutters pb-10 pt-60 shiftPlan2TextWrapper">
          <div className="reportingBlockHeadWrap">
            <div className="color-main mt-0 headWrapperMobile">
              <span>Mobile App</span>
            </div>
          </div>

          <div className="mb-20 color-heading f-18 medium reportingBlockDiscriptionWrapper">
            Mit der Aplano App können Ihre Mitarbeiter Schichten untereinander
            tauschen, sich auf offene Schichten bewerben oder Änderungsanträge
            einreichen. Um das Team up to date zu halten werden bei
            kurzfristigen Änderungen im Dienstplan Benachrichtigungen an die
            betroffenen Mitarbeiter versendet.
          </div>
          <CustomLinkButton
            link="https://www.aplano.de/registrieren"
            linkText="Jetzt loslegen"
          />
        </div>

        <div
          className="col-xl-5 col-lg-5 col-sm-14 shiftPlan2Imagewrapper"
          style={{ position: "relative" }}
        >
          <div className="shiftPlanImage2">
            <img
              alt="Aplano Dienstplan Schicht zuweisen"
              src={shiftplan2Img}
              className="mx-auto mw-full"
              style={{
                overflow: "hidden"
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

import React from "react";
import "./styles.scss";
import openShiftImg from "../../images/create-absence-landing-img.jpg";
import { CustomLinkButton } from "../../CustomLinkButton/CustomLinkButton";

export const Shiftplan1 = () => {
  return (
    <section
      className="pb-60 feature_11 shiftPlanMain1"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="container">
        <div className="row align-items-center shiftPlan1Wrapper">
          <div
            className="col-lg-5 col-xl-5 col-sm-14 autoWidthBox"
            style={{ position: "relative" }}
          >
            <div className="shiftPlanImage1">
              <img
                alt="Aplano Dienstplan offene Schichten"
                src={openShiftImg}
                className="openShiftImg gatsby-image-wrapper"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-sm-14 no-gutters pb-10 pt-30 shiftplan1TextWrapper">
            <div className="shiftPlanHeadWrap">
              <div className="mt-0 headWrapperVacation">
                <span>Urlaubsplaner</span>
              </div>
            </div>

            <div className="mb-20 color-heading f-18 medium shiftplanDiscriptionWrapper">
              Mit dem Abwesenheitskalender behalten Sie immer den Überblick, wer
              in welchen Zeiträumen nicht verfügbar ist, sodass Sie einen
              möglichen Personalmangel frühzeitig erkennen können. Im
              Schichtplan werden die Abwesenheiten entsprechend markiert.
            </div>
            <CustomLinkButton
              link="https://www.aplano.de/registrieren"
              linkText="Jetzt loslegen"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

import React from "react";
import "./styles.scss";

import icon1 from "../static/i/dienstplanung.svg";
import icon2 from "../static/i/zeiterfassung.svg";
import icon3 from "../static/i/abwesenheit.svg";

export const FeatureWrapper = props => {
  return (
    <div>
      <div className="f-18 color-heading medium text-center pt-40 mainFeatureWrapperLanding">
        <div className="row container customFeatureWrapper col-12">
          <div className="col-xl-4 col-lg-4 col-sm-12 f-18 pt-10 pb-10 text-adaptive featurePaper featurePaperLeft">
            <div className="featureHeaderWrapper">
              <img
                alt="Shift-Edit"
                src={icon1}
                className=""
                style={{ height: 30 }}
              />
              <h3 className="f-22 discriptionHead">{props.titleA}</h3>
            </div>
            <div className="mx-auto text-left discriptionWrapper">
              {props.textA}
            </div>
            <a
              href="https://www.aplano.de/schichtplaner"
              className="featureLinkTag"
            >
              <div className="discriptionLink mt-20">Mehr dazu</div>
            </a>
          </div>

          <div className="col-xl-4 col-lg-4 col-sm-12 f-18 pt-10 pb-10 text-adaptive featurePaper featurePaperMiddle">
            <div className="featureHeaderWrapper">
              <img
                alt="Zeiterfassung Icon"
                src={icon2}
                className=""
                style={{ height: 30 }}
              />
              <h3 className="f-22 discriptionHead">{props.titleB}</h3>
            </div>
            <div className="mx-auto text-left discriptionWrapper">
              {props.textB}
            </div>
            <a
              href="https://www.aplano.de/zeiterfassung"
              className="featureLinkTag"
            >
              <div className="discriptionLink mt-20">Mehr dazu</div>
            </a>
          </div>

          <div className="col-xl-4 col-lg-4 col-sm-12 f-18 pt-10 pb-10 text-adaptive featurePaper featurePaperRight">
            <div className="featureHeaderWrapper">
              <img
                alt="Abwesenheit Icon"
                src={icon3}
                className=""
                style={{ height: 30 }}
              />
              <h3 className="f-22 discriptionHead">{props.titleC}</h3>
            </div>
            <div className="mx-auto text-left discriptionWrapper">
              {props.textC}
            </div>
            <a
              href="https://www.aplano.de/urlaubsplaner"
              className="featureLinkTag"
            >
              <div className="discriptionLink mt-20">Mehr dazu</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

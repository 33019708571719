import React from "react";
import "./styles.scss";
import clockingImg from "../images/clocking-landing-mock.png";
import { CustomLinkButton } from "../CustomLinkButton/CustomLinkButton";

type Props = {
  text: string;
};

export const IndustrySection2 = (props: Props) => {
  return (
    <section className="feature_11 industrySectionMain2">
      <div
        className="row container px-xl-0 industrySection2Wrapper"
        style={{ alignItems: "center" }}
      >
        <div
          className="col-lg-6 col-xl-6 col-sm-14 autoWidthBox"
          style={{ position: "relative" }}
        >
          <div className="industrySectionImage2">
            <img
              alt="Aplano Dienstplan - Schichten"
              src={clockingImg}
              className="mw-full iSection2Img gatsby-image-wrapper"
            />
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-sm-14 no-gutters pb-0 pt-0 industrySection2TextWrapper">
          <div className="industrySection2HeadWrap">
            <div className="color-main mt-0 headWrapper">
              <span style={{ lineHeight: "40px" }}>Digitale Stempeluhr</span>
            </div>
          </div>

          <div className="mb-20 color-heading f-18 medium industrySection2DiscriptionWrapper">
            {props.text}
          </div>
          <CustomLinkButton
            link="https://www.aplano.de/registrieren"
            linkText="Jetzt loslegen"
          />
        </div>
      </div>
    </section>
  );
};
